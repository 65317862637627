import { Disclosure, Tab } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import B3SatisfyDetails from './B3SatisfyDetails';
import ExpandableImage from './ExpandableImage';

const categories = ['DETAILS', 'NUTRITION FACTS'];

export default function B3Satisfy() {
  return (
    <div className="w-full sm:px-0">
      <div className="hidden md:block w-full">
        <Tab.Group>
          <Tab.List className="flex pt-2">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  clsx(
                    'py-1 xl:text-xl font-light leading-5 mr-4 text-lg',
                    'focus:outline-none border-b-[2px] ',
                    selected
                      ? 'border-gray-800 mr-4'
                      : 'border-transparent'
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-1">
            <Tab.Panel key={0}>
              <B3SatisfyDetails />
            </Tab.Panel>
            <Tab.Panel key={1}>
              <ExpandableImage alt="b3 start nutrition facts" src="./assets/images/nutrition-facts/Satisfy-Supp-Facts.png" className='w-full' />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="md:hidden mx-auto w-full rounded-2xl shadow bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <span className='text-base'>DETAILS</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <B3SatisfyDetails />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <span className='text-lg'>NUTRITION FACTS</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <ExpandableImage alt="b3 start nutrition facts" src="./assets/images/nutrition-facts/Start-Free-Supp-Facts.png" className='w-full' />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

