import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState, Fragment } from "react";
import { Transition, Dialog } from '@headlessui/react'
import Button from "../../components/Button";
import { getCart } from "../../utils/localstorage";
import Items from "./Items";
import BillingInfo from "./BillingInfo";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { isProb3 } from "../../utils/siteOrigin";


export default function Confirmation() {
  const notify = () => toast.error("Order is Failed!");
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [taxData, setTaxData] = useState()
  const [taxes, setTaxes] = useState(0)
  // Loading state for the taxes
  const [taxesLoading, setTaxesLoading] = useState(true)
  const [shippingRate, setShippingRate] = useState(0)
  const [shippingRateLoading, setShippingRateLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenOrderFailedModal, setIsOpenOrderFailedModal] = useState(false);
  const shippingInfo = JSON.parse(localStorage.getItem('shippingAddress'))
  const billingInfo = JSON.parse(localStorage.getItem('billingAddress'))
  const cardInfo = JSON.parse(localStorage.getItem('cardInfo'))
  const paymentMethod = localStorage.getItem('paymentMethod')
  const memberWebsitePassword = localStorage.getItem('memberWebsitePassword')
  const memberWebsitePrefix = localStorage.getItem('memberWebsitePrefix')

  const [sponsorId, setSponsorId] = useState(searchParams.get('sponsorId') || localStorage.getItem('sponsorId') || 22)
  useEffect(() => {
    setSponsorId(prev => {
      if (prev === searchParams.get('sponsorId')) {
        return prev || localStorage.getItem('sponsorId') || 22
      }
      return searchParams.get('sponsorId') || localStorage.getItem('sponsorId') || 22
    })
  }, [searchParams])

  const cartProducts = getCart();
  const subTotal = useMemo(() => {
    return cartProducts.reduce((total, p) => {
      return total + p.product.Price * p.qty
    }, 0);
  }, [cartProducts])

  const payload = {
    ...shippingInfo,
    "SubTotal": subTotal,
    "LineItems": cartProducts.map(p => ({
      "ItemId": p.product.Sku,
      "QtyOrdered": p.qty,
      "WholeSalePrice": p.product.Price
    }))
  }

  useEffect(() => {
    fetch(`https://b3salestaxapi.azurewebsites.net/api/salestax`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(res => {
        if (res && !res.errors) {
          setTaxes(res.totalTaxAmount);
          setTaxData(res);
          setTaxesLoading(false)
        }
      }).catch(err => {
        toast.error(
          "There was an error calculating the taxes. Please try again later."
        )
      })

    fetch(`${process.env.REACT_APP_API_URI}/api/Product/shippingratesforlineitems`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "memberId": sponsorId,
        "shipToCountry": 'United States',
        "lineItems": cartProducts.map((p, index) => ({
          "itemId": p.product.Sku,
          "qtyOrdered": p.qty,
        }))
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res && !res.errors) {
          setShippingRate(res)
          setShippingRateLoading(false)
        }
      }).catch(err => {
        toast.error(
          "There was an error calculating the shipping rates. Please try again later."
        )
      })
  }, [])

  useEffect(() => {
    setTotal((subTotal + Number(taxes) + Number(shippingRate)) || 0);
  }, [subTotal, taxes, shippingRate])

  const submit = () => {
    setIsSubmitting(true)
    const payload = {
      "repId": 0,
      "shipToFirstName": shippingInfo.ShipToFirstName,
      "shipToLastName": shippingInfo.ShipToLastName,
      "shipToAddress": shippingInfo.ShipToAddress,
      "shipToAddress2": shippingInfo.ShipToApt,
      "shipToCity": shippingInfo.ShipToCity,
      "shipToState": shippingInfo.ShipToState,
      "shipToCountry": 'United States',
      "shipToPostalCode": shippingInfo.ShipToPostalCode,
      "shipToPhone": shippingInfo.ShipToPhone,
      "shipToEmail": shippingInfo.ShipToEmail,
      "country": "United States",
      "billToFirstName": billingInfo.firstName,
      "billToLastName": billingInfo.lastName,
      "billToAddress": billingInfo.address,
      "billToAddress2": billingInfo.apt,
      "billToCity": billingInfo.city,
      "billToState": billingInfo.state,
      "billToPostalCode": billingInfo.postalCode,
      "taxInvoiceId": taxData.invoiceNumber,
      "taxTransactionId": taxData.transactionId,
      "paymentMethodId": paymentMethod === 'credit' ? 1 : 3,
      "sponsorId": Number(sponsorId),
      "salesTax": Number(taxes),
      'memberWebSiteName': memberWebsitePrefix,
      "cart": isProb3() ? 'ProB3' : 'B3Sciences',
      "lineItems": cartProducts.map((p, index) => ({
        "id": index,
        "itemId": p.product.Sku,
        "itemDesc": p.product.Description,
        "qtyOrdered": Number(p.qty),
        "wholeSalePrice": p.product.Price,
        "bonus": 0,
        "promotion": 0
      })),
      "payment": {
        "cardNumber": paymentMethod === 'credit' ? cardInfo.cardNumber : '',
        "expirationDate": paymentMethod === 'credit' ? (cardInfo.expMonth.length === 1 ? `0${cardInfo.expMonth}` : cardInfo.expMonth) + cardInfo.expYear.slice(-2) : '',
        "securityCode": paymentMethod === 'credit' ? cardInfo.cvv : '',
        "amount": total,
        "name": paymentMethod === 'credit' ? cardInfo.cardholder : '',
        "firstName": billingInfo.firstName,
        "lastName": billingInfo.lastName,
        "street": billingInfo.address,
        "city": billingInfo.city,
        "state": billingInfo.state,
        "zipCode": billingInfo.postalCode,
        "phone": billingInfo.phone,
        "invoiceNum": "",
        "repId": "",
        "email": billingInfo.email,
        "customerProfileDescription": "",
        "customerProfileId": "",
        "customerPaymentProfileId": "",
        "creditCardLast4Digits": paymentMethod === 'credit' ? cardInfo.cardNumber.slice(-4) : '',
        "ipAddress": "",
      }
    }
    const lastPayload = memberWebsitePassword ? {
      ...payload,
      'passWord': memberWebsitePassword,
    } : payload
    fetch(`${process.env.REACT_APP_API_URI}/api/Order/add`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(lastPayload)
    })
      .then(res => res.json())
      .then(async res => {
        if (res) {
          localStorage.removeItem('cart')
          localStorage.removeItem('billingAddress')
          localStorage.removeItem('shippingAddress')
          localStorage.removeItem('cardInfo')
          localStorage.removeItem('memberWebsitePassword')
          localStorage.setItem('orderId', res)
          await fetch(`${process.env.REACT_APP_API_URI}/api/Order/${res}`, {
            method: 'get',
          }).then(res => res.json()).then((res) => {
            localStorage.setItem('memberId', res.repId)
          }).catch(err => {
            console.log(err)
          })
          navigate('/order-success')
        } else {
          notify();
          setIsOpenOrderFailedModal(true)
        }
        setIsSubmitting(false)
      })
  }

  const closeModal = () => {
    setIsOpenOrderFailedModal(false)
  }
  const getCardImagePath = (number) => {
    if (/^3[47]/.test(number)) {
      return './assets/images/cards/amex-card.jpeg';
    } else if (/^4/.test(number)) {
      return './assets/images/cards/visa-card.jpeg';
    } else if (/^5[1-5]/.test(number)) {
      return './assets/images/cards/mastercard-card.jpeg';
    } else if (/^6/.test(number)) {
      return './assets/images/cards/discover-card.jpeg';
    } else {
      return './assets/images/cards/default-card.jpeg'; // Path to a default image if card type is unknown
    }
  };
  const cardImagePath = useMemo(() => getCardImagePath(cardInfo?.cardNumber), [cardInfo?.cardNumber]);
  const formatCardNumber = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    const isAmex = cleanNumber.length === 15;
    const displayDigits = isAmex ? 3 : 4;
    const maskedNumber = cleanNumber.slice(0, -displayDigits).replace(/\d/g, 'x') + cleanNumber.slice(-displayDigits);
    return `${maskedNumber.slice(0, 4)}-${maskedNumber.slice(4, 8)}-${maskedNumber.slice(8, 12)}-${maskedNumber.slice(12)}`;
  };
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative text-gray-700">
      <Items subTotal={subTotal} taxes={taxes} shippingCost={shippingRate} total={total} />
      <div>
        <p className="text-lg border-b py-3 w-full">Payment Information</p>
        <div className="flex justify-between items-center py-6 border-b">
          {paymentMethod === 'credit' && <>
            <span className="text-base md:text-lg">Card number</span>
            <div className='flex gap-2 items-center'>
              <img alt="card" src={cardImagePath} className='w-[35px] md:w-[50px]' />
              <span className="text-sm md:text-base">{formatCardNumber(cardInfo.cardNumber)}</span>
            </div>
          </>}
          {paymentMethod === 'paypal' && <>
            <div className='flex gap-2 items-center'>
              <img alt="visa-card" src={`./assets/images/cards/paypal.png`} className='w-[100px]' />
            </div>
          </>}
        </div>
      </div>
      <BillingInfo billingInfo={billingInfo} />
      <div className='w-full flex justify-between items-center mt-5 md:mt-10 mb-7 gap-2'>
        <NavLink key='b3 bands' to="/payment">
          <Button className="uppercase text-xs md:text-base px-2 py-1 rounded-full font-light w-auto">
            {'< Back'}
          </Button>
        </NavLink>
        {paymentMethod === 'credit' && !taxesLoading && !shippingRateLoading &&
          <Button className="uppercase text-xs md:text-base px-6 py-2 rounded-full font-light w-auto" onClick={submit} disabled={isSubmitting}>
            {isSubmitting ? <div class="flex items-center justify-center">
              <div
                class="inline-block w-5 h-5 md:h-8 md:w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                  class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span
                >
              </div>
            </div> : 'Submit >'}
          </Button>
        }
        {paymentMethod === 'paypal' && !shippingRateLoading && !taxesLoading &&
          <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: total.toString(),
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  submit()
                });
              }}
              style={{ layout: 'horizontal', height: 35, color: "blue", borderRadius: 999 }}
              buttonStyles={{
                layout: 'custom',
                shape: 'rect',
                label: 'paypal'
              }}
            />
          </PayPalScriptProvider>}
      </div>
      <Transition appear show={isOpenOrderFailedModal} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="lg:max-w-3xl max-w-xl transform rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all sm:max-h-auto max-h-[600px] overflow-auto">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-[#00adef]"
                  >
                    Order Failed
                  </Dialog.Title>
                  <div>
                    <div className="mt-4">
                      <p>Your Credit Card Transaction has Declined</p>
                      <p>If you believe you made an error, go Back and resubmit your order</p>
                      <p className="mb-3">Otherwise please contact your Bank to determine the reason</p>
                      <p>You can also contact the B3 Support Team</p>
                      <a href="mailto:support@b3sciences.com" className="text-[#00adef]">support@b3sciences.com</a>
                    </div>
                    <div className='flex justify-center mt-3 w-full'>
                      <Button
                        type="button"
                        onClick={closeModal}
                        className="text-sm text-white px-4 py-2 rounded-full font-light mt-4 md:mt-0"
                        data-mdb-ripple="true" data-mdb-ripple-color="light">
                        Close
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  )
}