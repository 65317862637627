import { Fragment, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Button from '../../components/Button';
import PRODUCTS from '../../../public/assets/products.json';
import { getCart, saveCart } from '../../utils/localstorage';
import { toast } from 'react-toastify';

export default function Subscribe({ price, sku }) {
  const notify = () => toast("Product is added!");
  const [qty, setQty] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const product = PRODUCTS.find(p => p.Sku === sku);

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const confirmSubscribe = (e) => {
    closeModal();
    if (qty > 0) {
      notify();
      const cart = getCart()
      const existing = cart.find(p => p.product.Sku === sku)
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + qty
      } else {
        cart.push({ product, qty })
      }
      saveCart(cart)
    }
  }

  return (
    <div className="flex mt-8 sm:flex-row flex-col">
      <div className='flex items-center font-medium mr-4'>
        <span className='text-xl sm:mr-0 mr-4'>${price}</span>
        <Button 
          onClick={openModal}
          className="text-base md:text-lg px-5 py-2 rounded-full font-light"
          data-mdb-ripple="true" data-mdb-ripple-color="light">
          SUBSCRIBE & SAVE
        </Button>
      </div>
      <div className='flex items-center sm:mt-0 mt-4'>
        <span className='text-2xl mr-2'>QTY : </span>
        <input type="number" name="qty" id="qty" value={qty} onChange={(e) => setQty(e.target.value)} className="block w-full flex-1 rounded border md:border-gray-400 border-[#00adef] px-2 py-1.5 w-[65px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="Qty" />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="lg:max-w-5xl max-w-3xl transform rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-[#00adef] mb-5"
                  >
                    Confirmation
                  </Dialog.Title>
                  <button type="button" class="w-8 h-8 absolute top-4 right-4" onClick={closeModal}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="oc se"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></button>
                  <div>
                    <div className='flex flex-col items-start justify-center text-lg max-w-3xl'>
                      <p>
                        When choosing Subscribe and Save, you are agreeing to an initial order, and then recurring monthly orders on the same date of the month.
                      </p>
                      <p>
                        Your pay method used today will be used each month to process your order.
                      </p>
                      <p>
                        You can change, modify, or cancel your monthly order at any time, by emailing <a href = "mailto:support@b3sciences.com" className='underline'>support@b3sciences.com</a>
                      </p>
                    </div>
                    <div className='flex justify-center mt-3 w-full'>
                      <Button
                        type="button"
                        onClick={confirmSubscribe}
                        className="text-base md:text-lg px-5 py-2 rounded-full font-light"
                        data-mdb-ripple="true" data-mdb-ripple-color="light">
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}