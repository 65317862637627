import Button from "../../components/Button"

const categories = ['MEMBER BENEFITS'];

export default function SiteSelection(props) {
	const { checkAvailability, onContinue, setPrefix, prefix, available } = props

	return (
		<div>
			<h1 className='md:text-4xl pt-2 text-2xl sm:text-3xl'>
				<span className='text-[#00adef] mr-2'>Choose Your Personalized <span className="text-black">B</span>3 website</span>
			</h1>
			<div className="flex flex-col gap-16">
				<div className="flex flex-col">
					<div className="flex flex-col items-center gap-3 border mt-4 md:mt-6 rounded-lg max-w-2xl border-gray-300 w-full mx-auto sm:px-8 px-5 py-5">
						<span className="text-xl font-bold">Example: bill<span className="font-light">.b3sciences.com</span></span>
						<div className='flex items-center gap-3'>
							<input name="email" value={prefix} onChange={(e) => setPrefix(e.target.value)} className="block flex-1 rounded border border-gray-400 px-2 py-1.5 w-[100px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="prefix" />
							<span className='text-2xl mr-2 font-light'>.b3sciences.com</span>
						</div>
						{available && <div className={`text-md ${available === 'Available' ? 'text-green-500' : 'text-red-800'} `}>
							{available === 'Available' ? 'Congratulations, this will be your site name!' : 'Not available, please choose another name.'}
						</div>}
						<Button
							onClick={checkAvailability}
							className="text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
							data-mdb-ripple="true" data-mdb-ripple-color="light">
							CHECK AVAILABILITY
						</Button>
					</div>
					<Button
						onClick={onContinue}
						className="text-base md:text-lg px-5 py-2 rounded-full font-light mt-4 lg:max-w-[200px]"
						data-mdb-ripple="true" data-mdb-ripple-color="light">
						Continue
					</Button>
				</div>
			</div>
		</div>
	)
}