import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Pagination, EffectCube, Keyboard } from "swiper";
import { Dialog, Transition } from "@headlessui/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";

export default function Carousel(props) {
  const { swiperImages, allowExpand = false } = props
  return (
    <Swiper
      navigation={true}
      modules={[Navigation, Pagination, EffectCube, Keyboard]}
      pagination={{
        clickable: true,
      }}
      grabCursor={true}
      className={`h-auto w-full lg:h-[350px] border mb-3 lg:mb-0 lg:w-full ${props.className ?? ""}`}
      loop={true}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      effect={"cube"}
      keyboard={{
        enabled: true,
      }}
    >
      {swiperImages.map(img => (
        <SwiperSlide key={img}>
          <CarouselContent img={img} allowExpand={allowExpand} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

function CarouselContent({img,allowExpand}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return <div className="flex justify-center h-full"><Transition appear show={isPopupOpen} as={React.Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => setIsPopupOpen(false)}>
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <img alt={img} src={`./assets/images/${img}`} className="w-full h-full mt-2" />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
    <img alt={img} src={`./assets/images/${img}`} onClick={() => allowExpand && setIsPopupOpen(true)} />
  </div>
}