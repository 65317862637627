import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import Button from '../../components/Button';
import { cardFormSchema } from '../../utils/validation';

const expMonths = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
];

const expYears = (() => {
  const range = 10;
  const currentYear = (new Date()).getFullYear();
  let year;
  const years = [];
  for (let y = 0; y <= range; y++) {
    year = `${currentYear + y}`;
    years.push(year);
  }

  return years;
})();

export default function Payment() {
  let navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('');
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const savedCardInfo = JSON.parse(localStorage.getItem('cardInfo'));

  console.log('---- saved: ', savedCardInfo)

  const onChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    localStorage.setItem('paymentMethod', e.target.value);
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (paymentMethod !== 'paypal') {
      localStorage.setItem('cardInfo', JSON.stringify(values))
    }
    navigate('/confirmation')
  }

  return (
    <Formik initialValues={{ ...savedCardInfo, expYear: currentYear.toString(), expMonth: currentMonth.toString(), cvv: '' }} validationSchema={paymentMethod === 'paypal' ? undefined : cardFormSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, submitCount, handleSubmit }) => {
        return (
          <Form>
            <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full">
              <div className="flex flex-col text-gray-700" >
                <h3 className="text-2xl font-semibold py-4 border-b">Payment Methods</h3>
                <div className='flex items-center justify-between w-full border py-2 px-4 rounded mt-4'>
                  <div className="flex items-center gap-x-3 flex-1" onChange={onChangePaymentMethod}>
                    <input id="credit" name="payment-method" value="credit" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="credit" className="w-full block text-sm font-medium leading-6 text-gray-900">Credit Card</label>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <img alt="visa-card" src="./assets/images/cards/visa-card.jpeg" className='w-[50px]' />
                    <img alt="master-card" src="./assets/images/cards/mastercard-card.jpeg" className='w-[50px]' />
                    <img alt="amex-card" src="./assets/images/cards/amex-card.jpeg" className='w-[50px]' />
                    <img alt="discover-card" src="./assets/images/cards/discover-card.jpeg" className='w-[50px]' />
                  </div>
                </div>
                {paymentMethod === 'credit' &&
                  <div className="flex flex-col gap-4 border p-4 border-t-0">
                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className="flex flex-col w-full">
                        <span>Cardholder Name*</span>
                        <Field name="cardholder" className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="" />
                        {errors.cardholder && (touched.cardholder || submitCount > 0) && <span className="text-sm text-red-400">{errors.cardholder}</span>}
                      </div>
                      <div className="flex flex-col w-full">
                        <span>Card Number*</span>
                        <Field name="cardNumber" className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="" />
                        {errors.cardNumber && (touched.cardNumber || submitCount > 0) && <span className="text-sm text-red-400">{errors.cardNumber}</span>}
                      </div>
                    </div>

                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className="flex flex-col w-full">
                        <span>Expire Date*</span>
                        <Field as="select" name="expMonth" className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 h-[36px]" placeholder="">
                          {expMonths.map(month => (
                            <option value={month}>{month}</option>
                          ))}
                        </Field>
                        {errors.expMonth && (touched.expMonth || submitCount > 0) && <span className="text-sm text-red-400">{errors.expMonth}</span>}
                      </div>
                      <div className="flex flex-col w-full">
                        <span className='block lg:h-[24px] h-auto'></span>
                        <Field as="select" name="expYear" className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 h-[36px]" placeholder="">
                          {expYears.map(year => (
                            <option value={year}>{year}</option>
                          ))}
                        </Field>
                        {errors.expYear && (touched.expYear || submitCount > 0) && <span className="text-sm text-red-400">{errors.expYear}</span>}
                      </div>
                      <div className="flex flex-col w-full">
                        <span>CVV*</span>
                        <Field name="cvv" className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="" />
                        {errors.cvv && (touched.cvv || submitCount > 0) && <span className="text-sm text-red-400">{errors.cvv}</span>}
                      </div>
                    </div>
                  </div>
                }
                <div className="flex items-center w-full border py-1 px-4 rounded mt-4 justify-between" onChange={onChangePaymentMethod}>
                  <div className="flex items-center flex-1 gap-3">
                    <input id="paypal" name="payment-method" value="paypal" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="paypal" className="w-full block text-sm font-medium leading-6 text-gray-900">Paypal</label>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className='flex gap-3'>
                      <img alt="paypal credit" src="./assets/images/paypal-credit.png" className="w-20 m-[4px] rounded-sm" />
                      <img alt="paypal credit" src="./assets/images/paypal-yellow.png" className="w-20 m-[4px] rounded-sm" />
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full flex justify-between mt-10 mb-7 flex-row items-center'>
                <NavLink key='b3 bands' to="/shipping">
                  <Button className="uppercase text-xs md:text-base px-2 py-1 rounded-full font-light w-auto">
                    {'< Back'}
                  </Button>
                </NavLink>
                <Button className="uppercase text-xs md:text-base px-6 py-2 rounded-full font-light w-auto">
                  {'Next >'}
                </Button>
              </div>
            </section>
          </Form>
        )
      }}
    </Formik>
  )
}