import { createContext, useEffect, useState } from 'react';
import { getCart, saveCart } from '../utils/localstorage';

export const CartContext = createContext();

export function CartContextProvider({ children }) {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    setCart(getCart())
  }, [])

  const save = (cart) => {
    saveCart(cart)
    setCart(cart)
  }

  return (
    <CartContext.Provider value={{ cart, save }}>
      {children}
    </CartContext.Provider>
  );
}